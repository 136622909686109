import React from "react";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./Drawer";
import "./navbar.scss";
import { ChromeButton } from "../../components/ChromeButton";
import { NavLinks } from "../../components/NavLinks";
import { Logo } from "../../components/Logo";
import { Link } from "react-router-dom";
import { Container } from "@mui/system";
const Header = ({ hash, setHash }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <React.Fragment >

      <AppBar sx={{ background: "#F5F5F5", color: "#282B30", boxShadow: "none", padding: { md: '20px', sm: '16px', xs: '16px' }, borderBottom: "1px solid #282b302f" }}>
        <Container style={{ padding: 0 }}>
          <Toolbar style={{ padding: 0 }} className="toolbar">
            <Link to="/" onClick={() => { window.scrollTo(0, 0); }}>
              <Logo isMatch={isMatch} setHash={setHash} />
            </Link>
            {isMatch ? (
              <>
                <DrawerComp />
              </>
            ) : (
              <div className="nav_right">
                <NavLinks hash={hash} setHash={setHash} />
                <ChromeButton link="https://chrome.google.com/webstore/detail/locktheload-loads-booking/abfpabcljnjfonkbepfodpdembndjjnk" navbar={true} />
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
