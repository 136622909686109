// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/apercu-pro?styles=109347);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  font-family: "Apercu Pro", sans-serif;
}

a {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/scss/global.scss","webpack://./src/scss/App.scss"],"names":[],"mappings":"AAIA;EACE,SAAA;EACA,UAAA;EACA,qCAAA;ACAF;;ADIA;EACE,qBAAA;ACDF","sourcesContent":["@use \"./variables\" as *;\n@import url('https://fonts.cdnfonts.com/css/apercu-pro?styles=109347');\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');\n\n* {\n  margin: 0;\n  padding: 0;\n  font-family: 'Apercu Pro', sans-serif;\n\n}\n\na {\n  text-decoration: none;\n}","@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n@import url(\"https://fonts.cdnfonts.com/css/apercu-pro?styles=109347\");\n@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap\");\n* {\n  margin: 0;\n  padding: 0;\n  font-family: \"Apercu Pro\", sans-serif;\n}\n\na {\n  text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
