import * as React from 'react';
import { Container, Typography, Box, Stack } from '@mui/material';
import cycle from '../../assets/images/cycle3.png';
import { FreeBtn } from '../../components/FreeBtn';
import { Logo } from '../../components/Logo';
import { Social } from '../../components/Social';
import { NavLinks } from '../../components/NavLinks';

function Footer({ hash, setHash }) {
  return (
    <Box
      sx={{
        bgcolor: '#F5F5F5',
        px: { xs: 3, sm: 8 },
        py: 5,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '32px 32px 0 0',
        position: 'relative',
      }}
      component="footer"
    >
      <Container maxWidth="lg">
        <Box
          component="img"
          sx={{
            width: { xs: 165, sm: 305 },
            height: { xs: 105, sm: 265 },
            position: 'absolute',
            bottom: 0,
            left: { xs: 0, sm: -40 },
          }}
          src={cycle}
          alt="Cycle illustration"
        />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ textAlign: 'center', borderBottom: 1, borderColor: 'grey.500', pb: 2 }}
        >
          <Logo />

          <FreeBtn />
        </Stack>
        <Stack
          direction={{ xs: 'column-reverse', sm: 'column' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ my: 4 }}
        >
          <NavLinks hash={hash} setHash={setHash} />
          <Social />
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant='body2' color="text.secondary" sx={{ mt: { xs: 2, sm: 0 } }}>
            © 2023 LockTheLoad
          </Typography>
          <Typography
            component="a"
            href="/privacy"
            target="_blank"
            rel="noopener noreferrer"
            variant='body2'
            color="text.secondary"
            sx={{ textDecoration: 'none' }}
          >
            Privacy Policy & Terms Conditions
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}

export default Footer;
