import { Stack, Typography } from '@mui/material'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "./index.scss"
export const NavLinks = ({ hash, setHash }) => {
  let activeColor = "#FF6000";
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={4}
      component="ul"
      className="list"
    >
      <NavLink to="#features" onClick={() => setHash("#features")}>
        <HashLink smooth to="/#features" >
          <Typography
            component="li"
            onClick={() => {
              navigate("/#purchase");
            }}
            sx={{
              listStyleType: "none",
              fontSize: "20px",
              fontWeight: 400,
              marginRight: "24px",
              color:
                hash === "#features"
                  ? activeColor
                  : "#282B30",
            }}
            md={{ color: "red" }}
          >
            Features
          </Typography>
        </HashLink>
      </NavLink>
      <NavLink to="#prices" onClick={() => setHash("#prices")}>
        <HashLink smooth to="/#prices" >
          <Typography
            component="li"
            style={{
              listStyleType: "none",
              fontSize: "20px",
              fontWeight: 400,
              marginRight: "24px",
              color:
                hash === "#prices"
                  ? activeColor
                  : "#282B30",
            }}>Prices</Typography>
        </HashLink>
      </NavLink>
      <NavLink to="#about-us" onClick={() => setHash("#about-us")}>
        <HashLink smooth to="/#about-us" >
          <Typography
            component="li"
            style={{
              listStyleType: "none",
              fontSize: "20px",
              fontWeight: 400,
              marginRight: "24px",
              color:
                hash === "#about-us"
                  ? activeColor
                  : "#282B30",
            }}>About Us</Typography>
        </HashLink>
      </NavLink>
      <NavLink to="#blog" onClick={() => setHash("#blog")}>
        <HashLink smooth to="/blog">  <Typography
          component="li"
          style={{
            listStyleType: "none",
            fontSize: "20px",
            fontWeight: 400,
            marginRight: "24px",
            color:
              hash === "#blog"
                ? activeColor
                : "#282B30",
          }}>Blog</Typography></HashLink>
      </NavLink>
      <NavLink to="#faq" onClick={() => setHash("#faq")}>
        <HashLink smooth to="/#faq" >  <Typography
          component="li"
          style={{
            listStyleType: "none",
            fontSize: "20px",
            fontWeight: 400,
            marginRight: "24px",
            color:
              hash === "#faq"
                ? activeColor
                : "#282B30",
          }}>FAQ</Typography></HashLink>
      </NavLink>

    </Stack>
  )
}