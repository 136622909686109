import React from 'react'
import { extensionUrl } from "../config";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
export const FreeBtn = () => {
  return (
    <a className='HBtn' href={extensionUrl} target="_blank" rel="noreferrer">
      <PlayArrowIcon sx={{ color: "#6B6B6B" }} />
      <button id='start-free'>
        Start For Free!
      </button>
    </a>
  )
}