import React from 'react';
import './blog.scss';
import blogs from './blogs.json'
import { Box, Container, Typography, } from '@mui/material';
import blog1 from '../../assets/images/blog/blog1.png';
import blog2 from '../../assets/images/blog/blog2.png';
import blog3 from '../../assets/images/blog/blog3.png';
import blog4 from '../../assets/images/blog/blog4.png';
import { NavLink } from 'react-router-dom';
const BlogPostCard = ({ title, excerpt, link, image, datePosted, }) => {
  return (
    <Box className="notReverce">
      <NavLink to={`/blog/${link}`} onClick={() => { window.scrollTo(0, 0); }}>
        <img src={image} alt="Blog post cover png" className='blogImg' />
      </NavLink>
      <Box className="textCotainer">
        <NavLink to={`/blog/${link}`} onClick={() => { window.scrollTo(0, 0); }}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {datePosted}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {excerpt}
          </Typography>
        </NavLink>
        <NavLink to={`/blog/${link}`} onClick={() => { window.scrollTo(0, 0); }}>
          <Typography><button> Keep reading </button> </Typography>
        </NavLink>
      </Box>
    </Box>
  );
};
const Blogs = () => {
  return (
    <Box className="blog" id="blog">
      <Container maxWidth="lg" sx={{ marginBottom: "60px" }} >
        <Typography variant="h2" gutterBottom>
          Blog: Posts
        </Typography>
        {blogs.map(blog => {
          let id = blog.id
          return (
            <BlogPostCard
              image={id === 1 ? blog1 : id === 2 ? blog2 : id === 3 ? blog3 : blog4}
              title={blog.title}
              datePosted={blog.title_bottom}
              excerpt={blog.desc}
              link={blog.id}
              type="notReverce"
            />
          )
        })}
      </Container>
    </Box>
  );
};
export default Blogs;
