import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Person3Icon from '@mui/icons-material/Person3';
import gif from "../../assets/images/mainGif.mp4";
import "./banner.scss";
import { FreeBtn } from "../../components/FreeBtn";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export default function Banner() {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const typographyStyles = {
    mt: isMedium ? 1.25 : 2.5,
    fontStyle: 'normal',
    fontWeight: isMedium || isSmall || isExtraSmall ? 600 : 700,
    fontSize: isExtraSmall ? '32px' : isSmall ? '28px' : isMedium ? '42px' : '58px',
    lineHeight: isExtraSmall ? '36px' : isSmall ? '32px' : isMedium ? '52px' : '72px',
    width: isMedium || isSmall || isExtraSmall ? '100%' : '512px',
    color: isMedium ? "#282B30" : "#282B30",
  };
  const typographyStyles2 = {
    color: "#282B30",
    fontSize: isSmall || isExtraSmall ? '16px' : isMedium ? '18px' : '20px',
    fontWeight: isMedium ? 500 : 600,
    lineHeight: isSmall || isExtraSmall ? '20px' : isMedium ? '32px' : '25px',
    maxWidth: isExtraSmall ? '100%' : '512px',
    mb: isMedium ? 2.5 : 3.75,
  };
  return (
    <Container className='header'>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Typography variant='body1' sx={{ color: "#FF6000", display: "flex", alignItems: "center" }}>
              <Person3Icon sx={{ color: "#FF6000" }} />
              <span className="tool"> #1 Dispatcher Tool</span>
            </Typography>
            <Typography variant='h2' component='h1' sx={typographyStyles}>
              Book Hassle-Free DAT Loads<br /><span style={{ color: "#FF6000" }}>Faster!</span>
            </Typography>
            <div>
              <Typography variant='body2' sx={typographyStyles2}>
                Broker Auto-emails, Auto-calls, Load Profit Calculator, Google Maps and more.
              </Typography>
              <FreeBtn />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className='img'>
              <video
                controls={false}

                autoPlay
                muted
                loop
                className="gif"
                preload="auto"
              >
                <source src={gif} type="video/mp4" />
                Sorry, your browser doesn't support videos.
              </video>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
