import React from 'react'
import "./index.scss"
export const ChromeButton = ({ link, navbar = false }) => {
  return (
    <div sx={{ display: "flex" }} >
      <a href={link} target="_blank" rel="noopener noreferrer" >
        <button className='chrome_btn' style={{ background: navbar ? "#FF6000" : "#EFF0F2", color: navbar ? "#fff" : "#282B30" }}>Add to Chrome</button>
      </a>
    </div>
  )
}