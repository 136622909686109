import { Box } from '@mui/system';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react';
import './index.scss';
export const Social = () => {
  return (
    <Box className="social_box">

      <a href="https://www.linkedin.com/company/locktheload/" target="_blank" rel="noreferrer">
        <LinkedInIcon style={{ color: "#0277B5", fontSize: 40 }} />
      </a>
      <a href="https://www.facebook.com/locktheload" target="_blank" rel="noreferrer">
        <FacebookIcon style={{ color: "#1876D2", fontSize: 40 }} />
      </a>
      <a href="https://www.instagram.com/thelocktheload/?hl=en" target="_blank" rel="noreferrer">
        <InstagramIcon style={{ color: "#E0447B", fontSize: 37 }} />
      </a>
      <a href="https://t.me/locktheload" target="_blank" rel="noreferrer">
        <TelegramIcon style={{ color: "#1C8ADB", fontSize: 40 }} />
      </a>
    </Box>
  )
}