import React from 'react'
import LogoImg from "../assets/images/logo.svg";

export const Logo = ({ isMatch, setHash }) => {
  return (
    <div className='logo'>

      <a href="/" onClick={() => setHash("/")} >
        <img src={LogoImg} alt='Locktheload Logo' />
      </a>
    </div>
  )
}