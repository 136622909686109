import React from 'react';
import "./blog.scss";
import { useParams } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import blogs from './blogs.json'
import blogLogo from '../../assets/images/blog/blog_logo.svg';
import blog1 from '../../assets/images/blog/blog1.png';
import blog2 from '../../assets/images/blog/blog2.png';
import blog3 from '../../assets/images/blog/blog3.png';
import blog4 from '../../assets/images/blog/blog4.png';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
const BottomTitle = styled('div')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 32,
  lineHeight: '48px',
  color: '#6B6B6B',
  marginBottom: '20px !important',
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
    lineHeight: '24px',
  },
}));
const BlogItem = () => {
  const { id } = useParams();
  const heroStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${id === "1" ? blog1 : id === "2" ? blog2 : id === "3" ? blog3 : blog4})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    overflow: 'hidden',
  };
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 190.43%)',
    zIndex: 1,
  };

  const zoomEffect = {
    '&:hover': {
      transform: 'scale(1.1)',
      transition: 'transform 0.2s ease-in-out'
    }
  }
  return (
    <>
      <Box style={heroStyles} className="headBg">
        <Box style={overlayStyle} />
        <Box className="bannerHead" >
          <div className="blog_logo">
            <img src={blogLogo} alt="LockTheLoad logo white Png" />
            <Typography variant="subtitle1" component="b">
              BLOG
            </Typography>
          </div>
          <Typography variant="h3" className='head_title' component="h1" gutterBottom>
            {blogs[id - 1].title}
          </Typography>
          <Typography variant="subtitle1" component="p">
            {blogs[id - 1].publish_time}
          </Typography>
        </Box>
      </Box>
      <Container>
        <Box sx={{
          marginTop: 4,
          marginBottom: 4,
        }} className="blog_texts" >
          {
            blogs[id - 1].texts.map(text => {
              return (
                <Typography variant="body1" component="p" sx={{ textAlign: 'justify' }}>
                  {text}
                </Typography>
              )
            })
          }
        </Box>
        <Typography sx={{
          fontWeight: 400,
          fontSize: 32,
          lineHeight: '48px',
          color: '#6B6B6B',
          marginBottom: '20px !important',
          // Responsive Breakpoints
          '@media screen and (max-width: 600px)': {
            fontSize: 24,
            lineHeight: '24px',
          },
        }}
        >
          Related Posts
        </Typography>
        <Grid container className="bottom_blogs" spacing={2}>
          {blogs.map(blog => {
            let blogId = `${blog.id}`;
            if (blogId != id) {
              return (
                <Grid item sm={4} lg={4} md={4} sx={zoomEffect} >
                  <NavLink to={`/blog/${blogId}`} onClick={() => { window.scrollTo(0, 0); }}>
                    <div className="bottom_blog_item">
                      <img src={blogId === "1" ? blog1 : blogId === "2" ? blog2 : blogId === "3" ? blog3 : blog4} alt=" Truck on Routes, weather Png" />
                      <Typography variant='body1 ' component="p"  >
                        {blog.bottom_text}
                      </Typography>
                    </div>
                  </NavLink>
                </Grid>
              )
            }
          })}
        </Grid>
      </Container>
    </>
  );
};
export default BlogItem;
