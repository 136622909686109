// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDrawer-paperAnchorLeft {
  width: 46%;
  padding: 0 20px;
  padding-top: 30px;
}

.MuiIconButton-sizeMedium {
  background-color: #FF6000 !important;
  color: #fff !important;
  border-radius: 20% !important;
}

.navbar {
  border-bottom: 1px solid rgba(40, 43, 48, 0.1843137255);
}
@media screen and (min-width: 1540px) {
  .navbar {
    width: 1540px;
    margin: auto;
  }
}

.nav_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.drawer_social img {
  width: 28px !important;
  height: 28px !important;
  margin-left: -10px;
}`, "",{"version":3,"sources":["webpack://./src/views/Navbar/navbar.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,eAAA;EACA,iBAAA;AAAF;;AAGA;EACE,oCAAA;EACA,sBAAA;EACA,6BAAA;AAAF;;AAGA;EACE,uDAAA;AAAF;ACkBE;EDnBF;IAII,aAAA;IACA,YAAA;EACF;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAGE;EACE,sBAAA;EACA,uBAAA;EACA,kBAAA;AAAJ","sourcesContent":["@use \"../../scss/variables\" as *;\n\n.MuiDrawer-paperAnchorLeft {\n  width: 46%;\n  padding: 0 20px;\n  padding-top: 30px;\n}\n\n.MuiIconButton-sizeMedium {\n  background-color: #FF6000 !important;\n  color: #fff !important;\n  border-radius: 20% !important;\n}\n\n.navbar {\n  border-bottom: 1px solid #282b302f;\n\n  @include extraLarge {\n    width: 1540px;\n    margin: auto;\n  }\n}\n\n.nav_right {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: row;\n}\n\n.toolbar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: row;\n}\n\n.drawer_social {\n  img {\n    width: 28px !important;\n    height: 28px !important;\n    margin-left: -10px;\n  }\n}","@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n\n$fonts: \"Inter\", sans-serif !important;\n$bgColor: #F5F5F5;\n$colorBlack: #282B30;\n$mainColor: #FF6000;\n\n@mixin extraSmall {\n  @media screen and (max-width: 600px) {\n    @content;\n  }\n}\n\n@mixin Small {\n  @media screen and (max-width: 900px) {\n    @content;\n  }\n}\n\n@mixin Medium {\n\n  @media screen and (max-width: 1200px) {\n    @content;\n  }\n}\n\n@mixin Large {\n  @media screen and (max-width: 1540px) {\n    @content;\n  }\n}\n\n@mixin extraLarge {\n  @media screen and (min-width: 1540px) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
