import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import notfounfImg from "../../assets/images/404.png";
const NotFoundPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleGoHome = () => {
    navigate('/');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        p: 3,
        gap: 2,
        textAlign: 'center',
      }}
    >
      <Box
        component="img"

        sx={{
          width: '90%',
          maxWidth: '500px',
          height: 'auto',
          marginBottom: 2,
          position: "relative",
          zIndex: 9
        }}
        src={notfounfImg}
        alt="Not Found"
      />
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoHome}
          sx={{
            marginRight: theme.spacing(1), backgroundColor: "#00B2FF",
            '&:hover': {
              backgroundColor: "white",
              color: "#00B2FF"
            },
            textTransform: "capitalize"
          }}
        >
          Go Home Page
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
