import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  Stack,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./navbar.scss";
import { Social } from "../../components/Social";
import { NavLink, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
const DrawerComp = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [hash, setHash] = useState(window.location.hash)
  let activeColor = "#FF6000";
  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List style={{ width: "45%", marginBottom: "20px" }}>
          <Stack
            direction="column"
            alignItems="left"
            spacing={4}
            component="ul"
          >

            <NavLink to="#features" onClick={() => { setHash("#features"); setOpenDrawer(false) }}>
              <HashLink smooth to="/#features" >
                <Typography
                  component="li"
                  style={{
                    listStyleType: "none",
                    fontSize: "20px",
                    fontWeight: 400,
                    color:
                      hash === "#features"
                        ? activeColor
                        : "#282B30",
                  }}>Features</Typography>
              </HashLink>
            </NavLink>
            <NavLink to="#prices" onClick={() => { setHash("#prices"); setOpenDrawer(false) }}>
              <HashLink smooth to="/#prices" >
                <Typography
                  component="li"
                  style={{
                    listStyleType: "none",
                    fontSize: "20px",
                    fontWeight: 400,
                    color:
                      hash === "#prices"
                        ? activeColor
                        : "#282B30",
                  }}>Prices</Typography>
              </HashLink>
            </NavLink>
            <NavLink to="#about-us" onClick={() => { setHash("#about-us"); setOpenDrawer(false) }}>
              <HashLink smooth to="/#about-us" >
                <Typography
                  component="li"
                  style={{
                    listStyleType: "none",
                    fontSize: "20px",
                    fontWeight: 400,
                    color:
                      hash === "#about-us"
                        ? activeColor
                        : "#282B30",
                  }}>About Us</Typography>
              </HashLink>
            </NavLink>
            <NavLink to="#blog" onClick={() => { setHash("#blog"); setOpenDrawer(false) }}>
              <HashLink smooth to="/blog"> <Typography
                component="li"
                style={{
                  listStyleType: "none",
                  fontSize: "20px",
                  fontWeight: 400,
                  color:
                    hash === "#blog"
                      ? activeColor
                      : "#282B30",
                }}>Blog</Typography></HashLink>
            </NavLink>
            <NavLink to="#faq" onClick={() => { setHash("#faq"); setOpenDrawer(false) }}>
              <HashLink smooth to="/#faq" >  <Typography
                component="li"
                style={{
                  listStyleType: "none",
                  fontSize: "20px",
                  fontWeight: 400,
                  color:
                    hash === "#faq"
                      ? activeColor
                      : "#282B30",
                }}>FAQ</Typography></HashLink>
            </NavLink>
          </Stack>
        </List>
        <div className="drawer_social">

          <Social />
        </div>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
